import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ROUTE } from '@enx/shared/util/enums';
import { Observable } from 'rxjs';

import { AuthDataAccessService } from '../auth-data-access.service';

@Injectable({
  providedIn: 'root',
})
export class AuthDataAccessTokenGuard implements CanActivate {
  constructor(private authService: AuthDataAccessService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn()) {
      this.router.navigate([ROUTE.APP]);
      return false;
    }
    return true;
  }
}
