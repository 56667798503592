import { Injectable, inject } from '@angular/core';
import { SharedDataAccessApiService } from '@enx/shared/data-access';
import { Observable } from 'rxjs';

export interface HubSpotToken {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthDataAccessHubspotChatService {
  private readonly apiService = inject(SharedDataAccessApiService);
  private readonly RESOURCE_URL = 'user';

  public authenticateHubSpotUser(): Observable<{ token: string }> {
    return this.apiService.get<{ token: string }>(`${this.RESOURCE_URL}/authenticate-hubspot-user`, {
      withCredentials: true,
    });
  }
}
