import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalUser } from '@enx/auth/util';
import { SharedDataAccessApiService } from '@enx/shared/data-access';
import { LocalStorage, ROUTE } from '@enx/shared/util/enums';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface User {
  firstName: string;
  lastName: string;
  email: string;
}

interface Login {
  accessToken: string;
  refreshToken: string;
  isSuccess: boolean;
  message: string;
  user: User;
}

@Injectable({
  providedIn: 'root',
})
export class AuthDataAccessService {
  private readonly RESOURCE_URL = 'user';

  constructor(private router: Router, private apiService: SharedDataAccessApiService) {}

  login(email: string, password: string, isFirstLogin = false): Observable<Login> {
    return this.apiService
      .post<Login, { email: string; password: string }>(
        `${this.RESOURCE_URL}/login`,
        { email, password },
        { withCredentials: true },
      )
      .pipe(
        tap(({ accessToken, user }) => {
          this.setAccessToken(accessToken);
          this.setUserEmail(user);
          this.setRefreshTokenExpirationDate();
          isFirstLogin
            ? this.router.navigate([ROUTE.APP], { state: { firstLogin: true } })
            : this.router.navigate([ROUTE.APP]);
        }),
      );
  }

  refreshToken(): Observable<{ accessToken: string }> {
    return this.apiService
      .post<{ accessToken: string }, null>(`${this.RESOURCE_URL}/refresh-token`, null, { withCredentials: true })
      .pipe(
        tap(({ accessToken }) => {
          this.setAccessToken(accessToken);
        }),
      );
  }

  isLoggedIn(): boolean {
    return !this.isTokenExpired();
  }

  logout(): Observable<unknown> {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return of();
    }

    return this.apiService.post<void, { accessToken: string }>(`${this.RESOURCE_URL}/logout`, { accessToken }).pipe(
      tap(() => {
        this.clearHubSpotData();
        this.clearLocalStorage();

        this.router.navigate([ROUTE.LOGIN], { replaceUrl: true }).then(() => {
          window.location.reload();
        });
      }),
    );
  }

  isTokenExpired(): boolean {
    const token = this.getAccessToken();
    if (!token) {
      return true;
    }

    const tokenData = this.parseToken(token);

    if (!tokenData || !tokenData.exp) {
      return true;
    }

    const expirationDate = new Date(tokenData.exp * 1000);
    const currentDate = new Date();

    return currentDate > expirationDate;
  }

  getAccessToken(): string | null {
    return localStorage.getItem(LocalStorage.AUTH_TOKEN);
  }

  resetPassword(email: string): Observable<{ message: string }> {
    return this.apiService.post<{ message: string }, { email: string }>(`${this.RESOURCE_URL}/reset-password`, {
      email,
    });
  }

  setPassword(token: string, newPassword: string): Observable<{ message: string }> {
    return this.apiService
      .post<{ message: string }, { token: string; newPassword: string }>(`${this.RESOURCE_URL}/set-password`, {
        token,
        newPassword,
      })
      .pipe(
        tap(() => {
          this.router.navigate([ROUTE.LOGIN]);
        }),
      );
  }

  firstLogin(token: string, newPassword: string): Observable<Login> {
    return this.apiService
      .post<{ email: string; isSuccess: boolean; message: string }, { token: string; newPassword: string }>(
        `${this.RESOURCE_URL}/set-password`,
        {
          token,
          newPassword,
        },
      )
      .pipe(
        switchMap((response) => {
          if (response.isSuccess) {
            return this.login(response.email, newPassword, true);
          } else {
            return of();
          }
        }),
      );
  }

  isRefreshTokenExpired(): boolean {
    const expirationDateStr = localStorage.getItem(LocalStorage.EXPIRATION_DATE);
    if (!expirationDateStr) {
      return true;
    }

    const expirationDate = parseInt(expirationDateStr, 10);
    const currentDate = new Date().getTime();

    return currentDate > expirationDate;
  }

  initializeExternal(externalUser: ExternalUser): Observable<Login> {
    return this.apiService.post<Login, ExternalUser>(`${this.RESOURCE_URL}/initialize-external`, externalUser).pipe(
      tap(() => {
        this.router.navigate([ROUTE.APP], { state: { firstLogin: true } });
      }),
    );
  }

  exchangeToken(authorizationCode: string, token: string | null, externalUser: ExternalUser | null): Observable<Login> {
    const isDirectFlow: boolean = externalUser !== null;
    return this.apiService
      .post<Login, { authorizationCode: string; token: string | null; isDirectFlow: boolean }>(
        `${this.RESOURCE_URL}/exchange-token`,
        { authorizationCode, token, isDirectFlow },
      )
      .pipe(
        tap(({ accessToken, user }) => {
          this.setAccessToken(accessToken);
          this.setUserEmail(user);
          this.setRefreshTokenExpirationDate();
          if (externalUser === null) {
            this.router.navigate([ROUTE.APP], { state: { firstLogin: token !== null } });
          } else {
            this.initializeExternal(externalUser).subscribe();
          }
        }),
      );
  }

  verifyEmail(token: string): Observable<Login> {
    return this.apiService.post<Login, { token: string }>(`${this.RESOURCE_URL}/activate-account`, { token }).pipe(
      tap(({ accessToken, user }) => {
        this.setAccessToken(accessToken);
        this.setUserEmail(user);
        this.setRefreshTokenExpirationDate();
        this.router.navigate([ROUTE.APP], { state: { firstLogin: true } });
      }),
    );
  }

  private clearLocalStorage(): void {
    localStorage.clear();
  }

  private parseToken(token: string): { exp: number } | null {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Failed to parse token:', error);
      return null;
    }
  }

  private setAccessToken(token: string): void {
    localStorage.setItem(LocalStorage.AUTH_TOKEN, token);
  }

  private setUserEmail(user: User): void {
    localStorage.setItem(LocalStorage.USER_EMAIL, user.email);
  }

  private setRefreshTokenExpirationDate(): void {
    const currentDate = new Date();
    const datePlus7Days = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    const unixTimestamp = datePlus7Days.getTime();
    localStorage.setItem(LocalStorage.EXPIRATION_DATE, unixTimestamp.toString());
  }

  private clearHubSpotData(): void {
    if (typeof window !== 'undefined') {
      this.deleteCookie('messagesUtk');
      this.deleteCookie('hubspotutk');
      this.deleteCookie('__hstc');
      this.deleteCookie('__hssc');
      this.deleteCookie('__hssrc');

      if (window._hsq) {
        window._hsq = window._hsq.filter((item) => !['identify', 'setPrivacyConsent'].includes(item[0]));
      }

      const script = document.getElementById('hubspot-script');
      if (script) {
        script.remove();
      }

      if ('hsConversationsSettings' in window) {
        window.hsConversationsSettings = {
          identificationEmail: undefined,
          identificationToken: undefined,
        };
      }
    }
  }

  private deleteCookie(name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${window.location.hostname}`;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}
